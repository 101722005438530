import { number, object, setLocale, string } from "yup";

function locationRequiresMore(location) {
  return location === "france";
}

function subjectRequiresMore(subject) {
  return ["question", "order"].includes(subject);
}

function validationSchema(translations) {
  setLocale(translations);

  return object({
    subject: string().trim().required(),
    name: string().trim().required(),
    email: string().trim().required().email(),

    // Questions/Orders related fields
    company: string().when("subject", {
      is: subjectRequiresMore,
      then: s => s.trim().required(),
      otherwise: s => s.strip(),
    }),
    phone: string().when("subject", {
      is: subjectRequiresMore,
      then: s => s.trim().required(),
      otherwise: s => s.strip(),
    }),
    activity: string().when("subject", {
      is: subjectRequiresMore,
      then: s => s.trim().required(),
      otherwise: s => s.strip(),
    }),
    location: string().when("subject", {
      is: subjectRequiresMore,
      then: s => s.trim().required(),
      otherwise: s => s.strip(),
    }),
    department: string().when(["subject", "location"], {
      is: (subject, location) =>
        subjectRequiresMore(subject) && locationRequiresMore(location),
      then: s => s.trim().required(),
      otherwise: s => s.strip(),
    }),

    message: string().trim().required(),
    privacy: number().required(),
  });
}

export { locationRequiresMore, subjectRequiresMore, validationSchema };
