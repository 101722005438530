class Decoder {
  constructor(xor) {
    this.xor = xor;
  }

  apply(node) {
    node.querySelectorAll(".obfuscated").forEach(n => {
      n.setAttribute("href", this.xor.decode(n.getAttribute("href")));
    });
  }
}

export default Decoder;
