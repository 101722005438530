import { errorDisplay, formInput } from "../forms/markup";

function extract(form, namePattern) {
  const entries = Array.from(new FormData(form).entries());

  return entries.reduce((d, [k, v]) => {
    d[k.match(namePattern).groups.name] = v;
    return d;
  }, {});
}

function validate(form, extracted, validationSchema) {
  return validationSchema
    .validate(extracted, { abortEarly: false, stripUnknown: true })
    .catch(errors => {
      errors.inner.forEach(e =>
        errorDisplay(form, e.path, e.errors.join(", ")),
      );

      const first = errors.inner[0];
      formInput(form, first.path).focus();
    });
}

export { extract, validate };
