import {
  alertMessage,
  alertSuccessClass,
  errorDisplay,
  errorsReset,
  formTranslations,
} from "../forms/markup";
import { extract, validate } from "../forms/handlers";
import { validationSchema } from "./validations";

const namePattern = /newsletter\[(?<name>.+)\]/;

async function subscribe(form, data) {
  const url = `${form.dataset.formHandler}?action=${form.dataset.formAction}`;
  const body = Object.entries(data).reduce((d, [k, v]) => {
    d.append(k, v);
    return d;
  }, new FormData());

  return await fetch(url, { method: "POST", body }).then(response => {
    const json = response.json();
    return json["success"] === true;
  });
}

export default async form => {
  errorsReset(form);

  const translations = formTranslations(form);
  const extracted = extract(form, namePattern);
  const validated = await validate(
    form,
    extracted,
    validationSchema(translations.yup),
  );
  if (typeof validated === "undefined") {
    return;
  }

  if (subscribe(form, validated)) {
    return form.replaceWith(
      alertMessage(translations.newsletter.succeed, alertSuccessClass),
    );
  }

  return errorDisplay(form, "email", translations.failed);
};
