import { gsap } from "gsap";

export default {
  setup: () => {
    const wrapper = document.getElementById("mobile-nav-open");
    if (null === wrapper) {
      return;
    }

    const prefersReducedMotion = window.matchMedia(
      "(prefers-reduced-motion: reduce)",
    ).matches;

    function openMobileNav(e) {
      e.preventDefault();

      if (!prefersReducedMotion) {
        gsap.set(".mobile-overlay", {
          display: "flex",
          left: "100vw",
          pointerEvents: "none",
        });

        gsap.to(".mobile-overlay", 0.6, {
          left: 0,
          ease: "power3.out",
          onComplete: function () {
            gsap.set(".mobile-overlay", { pointerEvents: "unset" });
          },
        });
      } else {
        gsap.set(".mobile-overlay", { display: "flex" });
      }

      document.body.style.overflow = "hidden";
    }

    function closeMobileNav(e) {
      e.preventDefault();

      document.body.style.overflow = "visible";

      if (!prefersReducedMotion) {
        gsap.to(".mobile-overlay", 0.3, {
          left: "100vw",
          onComplete: function () {
            gsap.set(".mobile-overlay", { display: "none" });
          },
        });
      } else {
        gsap.set(".mobile-overlay", { display: "none" });
      }
    }

    var navopen = document.getElementById("mobile-nav-open");
    navopen.addEventListener("click", openMobileNav);
    navopen.addEventListener("touchstart", openMobileNav);
    var navclose = document.getElementById("mobile-nav-close");
    navclose.addEventListener("click", closeMobileNav);
    navclose.addEventListener("touchstart", closeMobileNav);
  },
};
