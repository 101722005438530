class Xor {
  #key;

  constructor(key) {
    this.#key = key;
  }

  get key() {
    return this.#key;
  }

  encode(input) {
    let output = "";

    for (let i = 0; i < input.length; ++i) {
      const hexInput = input.charCodeAt(i);
      const hexOutput = hexInput ^ this.key;

      output += this.fromHex(hexOutput);
    }

    return output;
  }

  decode(input) {
    let output = "";

    for (let i = 0; i < input.length; i += 2) {
      const hexInput = this.toHex(input, i);
      const hexOutput = hexInput ^ this.key;

      output += String.fromCharCode(hexOutput);
    }

    return output;
  }

  fromHex(hex) {
    let text = hex.toString(16);

    if (hex < 16) {
      text = "0" + text;
    }

    return text;
  }

  toHex(text, i) {
    const sequence = text.substr(i, 2);

    return parseInt(sequence, 16);
  }
}

export default Xor;
