import { Carousel } from "@fancyapps/ui/dist/carousel/carousel.esm.js";
import { Autoplay } from "@fancyapps/ui/dist/carousel/carousel.autoplay.esm.js";

function setupCarousel() {
  const wrapper = document.querySelector(".f-carousel");
  if (null === wrapper) {
    return;
  }

  new Carousel(
    wrapper,
    {
      center: false,
      infinite: true,
      slidesPerPage: 1,

      Autoplay: {
        showProgress: false,
        timeout: 3000,
      },
      Dots: {
        minCount: 2,
      },
      Navigation: false,
    },
    { Autoplay },
  ); 
}

export default {
  setup: () => {
    setupCarousel();
  },
};
