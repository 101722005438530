import SlimSelect from "slim-select";

import onSubmit from "./handler";
import { formControl, formInput } from "../../components/forms/markup";
import { locationRequiresMore, subjectRequiresMore } from "./validations";

function setupOnChangeEvents(form) {
  formInput(form, "subject").addEventListener("change", e => {
    const fields = ["activity", "company", "phone", "location"];
    const method = subjectRequiresMore(e.target.value) ? "remove" : "add";

    fields.forEach(name => formControl(form, name).classList[method]("hidden"));
    formInput(form, "location")
      .find(i => i.checked)
      ?.dispatchEvent(new Event("change", { bubbles: true }));
  });

  Array.from(formInput(form, "location")).forEach(i =>
    i.addEventListener("change", e => {
      const subject = formInput(form, "subject");
      const method =
        subjectRequiresMore(subject.value) &&
        locationRequiresMore(e.target.value)
          ? "remove"
          : "add";
      formControl(form, "department").classList[method]("hidden");
    }),
  );
}

function setupOnSubmitEvents(form) {
  form.addEventListener("submit", e => {
    e.preventDefault();
    onSubmit(e.target);
  });
}

function setupSelects(form) {
  form.querySelectorAll("[data-select]").forEach(e => {
    return new SlimSelect({
      select: e,
      settings: {
        showSearch: "selectSearchable" in e.dataset,
        searchText: e.dataset.selectNoResults,
        searchingText: e.dataset.selectSearchInProgress,
        searchPlaceholder: e.dataset.selectSearchPlaceholder,
        placeholderText: e.dataset.selectPlaceholder,
      },
    });
  });
}

export default {
  setup: () => {
    const form = document.querySelector('[data-form-action="contact"]');
    if (null === form) {
      return;
    }

    setupSelects(form);
    setupOnChangeEvents(form);
    setupOnSubmitEvents(form);
  },
};
