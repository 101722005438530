import { extract, validate } from "../../components/forms/handlers";
import {
  alertError,
  alertMessage,
  alertsReset,
  alertSuccessClass,
  errorsReset,
  formTranslations,
} from "../../components/forms/markup";
import { validationSchema } from "./validations";

const namePattern = /contact\[(?<name>.+)\]/;

async function sendEmail(form, data) {
  const url = `${form.dataset.formHandler}?action=${form.dataset.formAction}`;
  const body = Object.entries(data).reduce((d, [k, v]) => {
    d.append(k, v);
    return d;
  }, new FormData());

  return await fetch(url, { method: "POST", body }).then(response => {
    const json = response.json();
    return json["success"] === true;
  });
}

export default async form => {
  alertsReset(form);
  errorsReset(form);

  const translations = formTranslations(form);
  const extracted = extract(form, namePattern);
  const validated = await validate(
    form,
    extracted,
    validationSchema(translations.yup),
  );
  if (typeof validated === "undefined") {
    return;
  }

  if (sendEmail(form, validated)) {
    return form.replaceWith(
      alertMessage(translations.contact.succeed, alertSuccessClass),
    );
  }

  return alertError(form, translations.contact.failed);
};
