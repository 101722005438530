import onSubmit from "./handler";

function setupOnSubmitEvents(form) {
  form.addEventListener("submit", e => {
    e.preventDefault();
    onSubmit(e.target);
  });
}

export default {
  setup: () => {
    const form = document.querySelector('[data-form-action="newsletter"]');
    if (null === form) {
      return;
    }

    setupOnSubmitEvents(form);
  },
};
