const alertErrorClass = "alert-error";
const alertSuccessClass = "alert-success";
const controlSelector = "[data-form-control]";
const invalidControlClass = "form-control-invalid";
const invalidFeedbackClass = "invalid-feedback";

function alertError(form, message) {
  form.prepend(alertMessage(message, alertErrorClass));
}

function alertMessage(message, alertClass) {
  const alert = document.createElement("div");
  alert.classList.add(alertClass);
  alert.innerHTML = message;

  return alert;
}

function alertsReset(form) {
  form.querySelector(`.${alertErrorClass}, .${alertSuccessClass}`)?.remove();
}

function alertSuccess(form, message) {
  form.prepend(alertMessage(message, alertSuccessClass));
}

function errorDisplay(...args) {
  const message = args.pop();
  const control = formControl(...args);
  const inputs = [formInput(...args)].flat();
  const error = errorMessage(message);

  control.classList.add(invalidControlClass);
  inputs.forEach(i =>
    i.setAttribute("aria-describedby", error.getAttribute("id")),
  );
  control.append(error);
}

function errorMessage(message) {
  const error = document.createElement("div");
  error.setAttribute("id", uuidv4());
  error.classList.add(invalidFeedbackClass);
  error.innerHTML = message;

  return error;
}

function errorReset(...args) {
  const control = formControl(...args);
  const inputs = [formInput(...args)].flat();

  inputs.forEach(i => i.removeAttribute("aria-describedby"));
  control.classList.remove(invalidControlClass);
  control.querySelector(`.${invalidFeedbackClass}`)?.remove();
}

function errorsReset(form) {
  formControls(form).forEach(control => errorReset(control));
}

function formControl(...args) {
  if (args.length === 1) {
    return args[0];
  }

  const inputs = formInput(args[0], args[1]);
  if (inputs instanceof Array) {
    return inputs.at(0).closest(controlSelector);
  }
  return inputs.closest(controlSelector);
}

function formControls(form) {
  return form.querySelectorAll(controlSelector);
}

function formInput(form, name) {
  const inputs = form.querySelectorAll(`[name$="[${name}]"]`);
  return inputs.length === 1 ? inputs.item(0) : Array.from(inputs);
}

function formTranslations(form) {
  return JSON.parse(form.querySelector("[data-form-translations]").textContent);
}

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );
}

export {
  alertError,
  alertsReset,
  alertMessage,
  alertSuccess,
  alertSuccessClass,
  errorDisplay,
  errorMessage,
  errorReset,
  errorsReset,
  formControl,
  formInput,
  formTranslations,
};
