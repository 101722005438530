import { Fancybox } from "@fancyapps/ui";
import { en } from "@fancyapps/ui/dist/fancybox/l10n/en.esm.js";
import { fr } from "@fancyapps/ui/dist/fancybox/l10n/fr.esm.js";

function setupFancybox() {
  const fancy = document.querySelectorAll("[data-fancybox]");
  if (0 === fancy.length) {
    return;
  }

  const html = document.querySelector("html");
  const lang = html.getAttribute("lang");

  Fancybox.bind("[data-fancybox]", {
    l10n: lang == "en" ? en : fr,
    closeButton: true,
    closeExisting: true,
    contentClick: false,
    defaultType: "iframe",
    Html: {
      autoSize: false,
    },
  });
}

function resizeProjects(projects, header, wrapper) {
  const sizes = [0, 0, 0];

  projects.forEach((p, i) => {
    const style = p.currentStyle || window.getComputedStyle(p);
    const margin = Number(
      style.marginBottom.substr(0, style.marginBottom.length - 2),
    );
    sizes[i % 3] += p.clientHeight + margin;
  });

  const max = Math.max(...sizes);
  const style = header.currentStyle || window.getComputedStyle(header);
  const additionalMargin = Number(
    style.marginBottom.substr(0, style.marginBottom.length - 2),
  );

  wrapper.setAttribute(
    "style",
    `height: ${Math.ceil(max + additionalMargin)}px`,
  );
}

function setupProjects() {
  const projects = document.querySelectorAll("ul.projects > li");
  if (0 === projects.length) {
    return;
  }

  const wrapper = document.querySelector("ul.projects");
  const header = document.querySelector("main > header");

  Promise.all(
    Array.from(document.images)
      .filter(img => !img.complete)
      .map(
        img =>
          new Promise(resolve => {
            img.onload = img.onerror = resolve;
          }),
      ),
  ).then(() => {
    window.addEventListener("resize", () =>
      resizeProjects(projects, header, wrapper),
    );
    resizeProjects(projects, header, wrapper);
  });
}

export default {
  setup: () => {
    setupProjects();
    setupFancybox();
  },
};
