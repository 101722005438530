import Decoder from "@picourt-cabis/site-2024/plugins/obfuscate/Decoder.mjs";
import Xor from "@picourt-cabis/site-2024/plugins/obfuscate/Xor.mjs";

export default {
  setup: () => {
    const style = document.querySelector("style[data-key]");
    if (null === style) {
      return;
    }

    const key = parseInt(style.dataset.key);
    new Decoder(new Xor(key)).apply(document);
  },
};
