import Navigation from "./components/navigation";
import Newsletter from "./components/newsletter";
import Obfuscate from "./components/obfuscate";

// Page specific scripts
import Contact from "./pages/contact";
import Index from "./pages/index";
import Projects from "./pages/projects";

document.addEventListener("DOMContentLoaded", () => {
  Navigation.setup();
  Newsletter.setup();
  Obfuscate.setup();

  Contact.setup();
  Index.setup();
  Projects.setup();
});
