import { object, setLocale, string } from "yup";

function validationSchema(translations) {
  setLocale(translations);

  return object({
    email: string().trim().required().email(),
    language: string().trim().required(),
  });
}

export { validationSchema };
